import { ISitemapNode } from "@cloudfun/core";

const sitemap: ISitemapNode = {
  icon: "HomeIcon",
  to: "",
  title: "Home",
  subNodes: [
    {
      icon: "fa-bicycle",
      to: "bike/order",
      title: "訂單配車"
    },
    {
      icon: "ListIcon",
      to: "",
      title: "訂單管理",
      subNodes: [
        {
          icon: "ListIcon",
          to: "order",
          title: "訂單管理",
        },
        {
          icon: "fa-search",
          to: "bike/estimate",
          title: "查詢預估車輛"
        },
        {
          icon: "fa-file-alt",
          to: "order/done",
          title: "保險資料"
          // title: "已完成訂單"
        },
        // {
        //   icon: "fa-credit-card",
        //   to: "order/payment",
        //   title: "第三方支付訂單"
        // },
      ]
    },
    {
      icon: "fa-bicycle",
      to: "",
      title: "車輛管理",
      subNodes: [
        {
          icon: "fa-bicycle",
          to: "bike",
          title: "車輛管理"
        },
        // {
        //   icon: "fa-map-marker-alt",
        //   to: "bike/inventory",
        //   title: "站點庫存"
        // },
        // {
        //   icon: "fa-exchange",
        //   to: "bike/transfer",
        //   title: "車輛調度"
        // },
        {
          icon: "fa-file-alt",
          to: "bike/quantity",
          title: "既有車輛統計"
        },
      ]
    },
    {
      icon: "UserIcon",
      to: "user",
      title: "用戶管理",
      subNodes: [
        {
          icon: "fa-magic",
          to: "user-wizard",
          title: "用戶註冊精靈"
        },
        {
          icon: "UsersIcon",
          to: "role",
          title: "角色管理"
        },
        {
          icon: "UserIcon",
          to: "user",
          title: "用戶管理"
        }
      ]
    },
    {
      icon: "ActivityIcon",
      to: "action-log",
      title: "操作紀錄"
    },
    {
      icon: "UserIcon",
      to: "user",
      title: "系統設定管理",
      subNodes: [
        {
          icon: "SettingsIcon",
          to: "configuration",
          title: "系統配置"
        },
        {
          icon: "TagIcon",
          to: "tag",
          title: "標籤管理"
        },
        {
          icon: "MapPinIcon",
          to: "area",
          title: "景點管理"
        },
        {
          icon: "fa-sitemap",
          to: "category/store",
          title: "商家分類管理"
        },
        {
          icon: "HomeIcon",
          to: "store",
          title: "商家管理"
        },
        {
          icon: "FlagIcon",
          to: "station",
          title: "站點管理"
        },
        {
          icon: "fa-sitemap",
          to: "category/product",
          title: "產品分類管理"
        },
        {
          icon: "LayersIcon",
          to: "product",
          title: "產品管理"
        },
      ]
    },
    {
      icon: "FlagIcon",
      to: "banner",
      title: "廣告管理"
    },
    // {
    //   icon: "ImageIcon",
    //   to: "album",
    //   title: "相簿管理"
    // },
    {
      icon: "fa-pager",
      title: "內文管理",
      to: "template/AboutUs",
      subNodes: [
        {
          icon: "fa-info-circle",
          to: "template/AboutUs",
          title: "關於明潭好行"
        },
        {
          icon: "fa-dolly-flatbed",
          to: "template/Transaction",
          title: "交易注意事項"
        },
        {
          icon: "fa-dolly-flatbed",
          to: "template/Rent",
          title: "騎乘注意事項"
        },
        {
          icon: "fa-user-shield",
          to: "template/PrivacyPolicy",
          title: "隱私權聲明同意書"
        },
        {
          icon: "fa-user-shield",
          to: "template/Insurance",
          title: "保險條款"
        },
        {
          icon: "fa-info",
          to: "template/RentOnline",
          title: "網路預約租車"
        },
        {
          icon: "fa-info",
          to: "template/RentOffline",
          title: "現場租車"
        }
      ]
    },
    // {
    //   icon: "fa-question",
    //   to: "faq",
    //   title: "常見問題管理"
    // },
    // {
    //   icon: "fa-users",
    //   title: "會員管理",
    //   to: "member",
    //   subNodes: [
    //     {
    //       icon: "fa-user",
    //       to: "member",
    //       title: "會員資訊"
    //     },
    //     {
    //       icon: "fa-message",
    //       to: "comment",
    //       title: "評論管理"
    //     }
    //   ]
    // },
    {
      icon: "fa-users",
      to: "member",
      title: "會員資訊"
    },
    {
      icon: "fa-message",
      to: "comment",
      title: "評論管理"
    },
    {
      icon: "fa-dollar",
      to: "commission",
      title: "佣金紀錄"
    },
  ]
};

export default sitemap;
